import styled from "styled-components";
import { Link } from "react-router-dom";

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: 10vh;
    @media (max-width: 778px) {
        margin-right: 2vh;
    }
    position: relative;
    font-size: 1vh;
    width: 162px;    
    cursor: pointer;


    
`;

const Img = styled.img`
    height: 162px;
    width: 162px;
    object-fit: cover;
    object-position: center;
    filter:grayscale(100%);
`;


const Name = styled.h3`
    margin-top: 10px;
    font-size: 1rem;
    &:first-letter{
        text-transform: uppercase;
    }
    text-align: left;
    width:100%;
    transition: all 0.3s ease-in;

`;

const Position = styled.h4`
    text-transform: uppercase;
    font-weight: 400;
    color: black;
    font-size: 0.8rem;
    `;

const TextWraper = styled.div`
    display: flex;
    flex-direction: column;
    bottom: 0;
    width:100%;
    `;

const Company = styled.h4`
    display: block;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 600;
    color:#D12834;
    
    `;

const GouvernancePosition = styled.h2`
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;

    width: 100%;
    
    text-transform: uppercase;
    font-size: 1.1rem;
    color:#D12834;
    `;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: black;
`

const StyledExternalLink = styled.a`
    text-decoration: none;
    color: black;
`

const InsideCard = ({member, gouv, link=null}) => {
    return (
        <CardWrapper >
                <Img src={process.env.REACT_APP_API_URL+member.picture.url} alt={member.name+' '+member.surname} width='162px' height='162px'/>
                <TextWraper >
                    <Name >{member.name} {member.surname}</Name>
                </TextWraper>
                {gouv && link === null && <GouvernancePosition>{member.gouvernance_position ? member.gouvernance_position : 'membre'}</GouvernancePosition>}
                <TextWraper className='link-participant'>
                    <Position>{member.position}</Position>
                    <Company >{member.company && member.company.name}</Company>
                </TextWraper>
            </CardWrapper>
    )
}



export default function Card({member, gouv, link=null}) {
    if(link){
        return (
            <StyledLink to={link}>
                <InsideCard member={member} gouv={gouv}  link={link}/>
            </StyledLink>
        )
    }

    if(!member.cv || member.cv === null || member.cv === undefined || member.cv === '' || member.cv.length === 0){
        return (
            <StyledExternalLink href={member.external_link} target="_blank" rel="noopener noreferrer">
                <InsideCard member={member} gouv={gouv}/>
            </StyledExternalLink>
        )
    }


    return (
        <StyledLink to={`/member/${member.name}_${member.surname}`}>
            <InsideCard member={member} gouv={gouv} />
        </StyledLink>
    )
}

