import styled from "styled-components";
import Title from "../../Components/Title";
import SliderMembers from "./SliderMembers";
import { getEvent } from "../../apiDefault/events";
import React from "react";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import "moment/locale/fr";

const SectionCover = styled.section `
  display: flex;
  flex-direction: column;
  padding: 10vh 0 10vh 0;
  @media (max-width: 778px) {
    padding: 20vh 5vh 5vh 5vh;
  }
`;

const Nav = styled.nav `
  margin-top: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 778px) {
    flex-direction: column;
  }
`;

const Red = styled.span `
  color: #d12834;
  font-weight: 600;
`;

const RedBar = styled.div `
  width: 100%;
  height: 2px;
  background-color: #d12834;
  margin: 5vh 0 4vh 0;
  @media (max-width: 778px) {
    margin-bottom: 1vh;
  }
`;

const Section = styled.section `
  padding: 0 10vh;
  display: flex;
  // flex-direction: column;
  gap: 5vw;
  @media (max-width: 778px) {
    padding: 0 5vmin;
    gap: 2vh;
    flex-direction: column;
  }
`;

const Description = styled.p `
  margin: auto;
  text-align: center;
  width: 65%;
  font-family: Montserrat, sans-serif;
  padding-top: 2vh;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const DescriptionContent = styled.div `
  margin: auto;
  text-align: center;
  width: 65%;
  font-family: Montserrat, sans-serif;
  padding-top: 2vh;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 20px;
}



  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ButtonDefault = styled.a `
  justify-self: center;
  width: 200px;
  text-align: center;
  margin: 0 2.5vh;
  @media (max-width: 778px) {
    width: 225px;
    margin: 0 auto 3vh auto;
  }

  padding: 1vh;
  font-size: 18px;
  border-top: 2px solid #d12834;
  color: black;
  background-color: #d12834;
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-in;

  &:hover {
    border-top: 2px solid black;
    color: #f83030a1;
    background-color: white;
  }
`;

function formatHour(time) {
    let hours = time.slice(0, 2);
    let minutes = time.slice(3, 5);

    // Convertir les heures en nombre entier pour supprimer les zéros initiaux
    hours = parseInt(hours, 10);

    // Si les minutes ne sont pas "00", tu peux les ajouter. Sinon, afficher juste l'heure avec "h".
    return minutes === "00" ? hours + "h" : hours + "h" + minutes;
}

export default function ConnectDay() {
    const [event, setEvent] = React.useState(null);
    const [date, setDate] = React.useState(null);
    const [time, setTime] = React.useState(null);

    async function getInfoEvent() {
        const response = await getEvent();
        setEvent(response[0]);
        // FORMAT DATE AND DAY FOR THE FUTURE EVENT
        let formattedDate = moment(response[0]?.date).format("dddd Do MMMM YYYY");
        const day =
            formattedDate.split(" ")[0].charAt(0).toUpperCase() +
            formattedDate.split(" ")[0].slice(1);
        formattedDate =
            day +
            " " +
            formattedDate.split(" ")[1] +
            " " +
            formattedDate.split(" ")[2];
        setDate(formattedDate);

        const start_time = formatHour(response[0]?.start_time);
        const end_time = formatHour(response[0]?.end_time);

        setTime("de " + start_time + " à " + end_time);
    }

    const Banner = styled.div `
    background: #16055d;
    background-image: ${(props) => `url(${props.backgroundImage})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
    min-height: 50vh;
    @media (max-width: 850px) {
    background-image: ${(props) => `url(${props.backgroundImageMobile})`};
    }
  `;

  React.useEffect(() => {
    getInfoEvent();
  }, []);

  return (
    <main>
      <SectionCover>
        <Banner
          id="banner"
          backgroundImage={
            process.env.REACT_APP_API_URL + event?.banner_picture?.url
          }
          backgroundImageMobile={
            process.env.REACT_APP_API_URL + event?.banner_mobile?.url
          }
        ></Banner>
        <div id="bannerText">
          <DescriptionContent className="events-content">
            <ReactMarkdown>{event?.content}</ReactMarkdown>
          </DescriptionContent>
          {/* <Nav>
            <ButtonDefault
              target="_blank"
              href="https://mrbiplic985.typeform.com/to/WNS9aIlA"
              className="active"
            >
              Déposer ma candidature
            </ButtonDefault>
          </Nav> */}
        </div>

        <RedBar />
        <Section>
          <Title h="h2" size="3vmin" className="title-slider">
            Dirigeants participants
          </Title>

          <SliderMembers />
        </Section>
        {/* <Nav>
          <ButtonDefault
            target="_blank"
            href="https://mrbiplic985.typeform.com/to/WNS9aIlA"
            className="active"
          >
            Déposer ma candidature
          </ButtonDefault>
        </Nav> */}

        {/*   */}
      </SectionCover>
    </main>
  );
}