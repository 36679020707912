import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import logoNav from '../images/logo-top20-vector.svg';
import BoutonContact from "./BoutonContact";

  // ----- CONTAINER NAV ----- //
  // -BUG- jutify-content marche pas correctement 
  const Nav = styled.nav`
    width:100%;
    @media (min-width: 1935px) {
      width: 1933px;  
 
    }
    z-index:777;
    padding: 0 2rem;
    background: rgb(250,250,250);
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
    top: 0;
    ${({navbar}) => (navbar ? " border-bottom: solid 1px black;" : "")}
    `;

  // ----- STYLED DIV LOGO ----- //
  const Divlogo = styled.div `
    padding: 0.5em;
  `;

  // -- styled logo -- //
  const Logo = styled.img `
      width: 125px;
      margin:10px 0;
  `;

  const MenuLink = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 850px) {
      width: 100%;
      overflow: hidden;
      flex-direction: column;
      max-height: ${({isOpen}) => (isOpen ? "300px" : "0")};
      transition: max-height 0.5s ease-in;
    }
  `;

  const Acces = styled(Link)`
    text-decoration: none;
    padding: 1rem 2rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    cursor: pointer;
    color: black;
    text-transform: uppercase;
    font-size: 15px;
    transition: all 0.3s ease-in;

    &:hover {
      color:#f83030a1;
    }
  `;

  


// ---- RESPONSIVE NavBar ---- //
  const Burger = styled.div`
    display:none;
    flex-direction: column;
    cursor:pointer;

    span {
      width: 25px;
      height: 2px;
      background-color: grey;
      margin-bottom: 3px;
    }
    @media (max-width: 850px) {
      display:flex;
    }
  `;
  



const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);


  const changeBackground = () => {
    window.scrollY >= 40 ? setNavbar(true) : setNavbar(false);
  }

  window.addEventListener('scroll', changeBackground);

    return (
        <Nav navbar={navbar}>
            <Link to="/">
              <Divlogo>
                    <Logo src={logoNav} alt="Logo TOP20" width="85px"/>
              </Divlogo>  
            </Link>

          <Burger onClick={() => setIsOpen(!isOpen)}>
            {/* Span are use to format the burger button */}
            <span></span>
            <span></span>
            <span></span>

          </Burger>

          <MenuLink onClick={() => setIsOpen(false)} isOpen={isOpen}>

              <Acces to="/">Accueil</Acces>
              <Acces to="/club">Qui sommes nous ?</Acces>
              <Acces to="/thematiques">Nos actions</Acces>
              <Acces to="/connect-day">Connect day</Acces>
              <BoutonContact onClose={() => setIsOpen(false)} />
          </MenuLink>
        </Nav>
    );
};

export default Navbar;
